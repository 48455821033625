<template>
    <div class="loader">
      <div v-if="loader == true" class="loader-38"></div>
    </div>
    <div v-if="$store.state.loader == false" v-show="loader != true" class="content">
        <div class="modal" v-if="modal != false">
            <div class="modal_window">
                <div style="
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #1C1C1C;"
                >Укажи ссылки на портфолио</div>
                <div style="
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                text-align: center;
                color: #666666;"
                >Ты можешь указать ссылки на свои<br>соцсети или сайт</div>
                <img  @click="(modal = !true) || (border = false)"  class="cross_close" src="../img/cross_close.svg" alt="">
                <div class="input_modal_block" >
                    <input v-model="link" class="reg_input padding" :class="{errorBorder: ((border == true) || error == true) }" placeholder="https://example.com/">
                    <div class="linck" @click="addLink() || (Border())"><img src="../img/plus.svg" alt=""></div>                    
                </div>
                <div v-if="error == true" style="
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 19px;
                color: #EC6C6C;">Можно указать только одну ссылку на одну соцсеть</div>
            </div>
        </div>
        <div class="absolute1"></div>
        <div class="absolute2"></div>
        <div class="middle" style="margin-top: 12px; ">
            <div class="phorm change">
                <img class="back_arrow" @click="backRout()" src="../img/arrow.svg" alt="">
                <input v-model="FirstName"
                @blur="v$.FirstName.$touch"
                @focus="icon1 = true"
                :class="{invalid: v$.FirstName.$error}"
                class="reg_input change_input padding" type="text" placeholder="Поиск">
                <input v-model="LastName"
                @blur="v$.LastName.$touch"
                @focus="icon2 = true"
                :class="{invalid: v$.LastName.$error}"
                 class="reg_input change_input padding" type="text" placeholder="Поиск">
                <div class="check_icon">
                    <div style="margin-top: 3px" class="icon_img">
                        <img   v-show="(icon1 == true) && (FirstName != '')" @click="FirstName = ''" :src='NOiconOk' alt="">
                    </div>
                    <div style="margin-top: 3px" class="icon_img">
                        <img  v-show="(icon2 == true) && (FirstName != '')" @click="LastName = ''"  :src='NOiconOk' alt="">
                    </div>
                </div>
                <input v-model="$store.state.profile.job" @focus="(modal1 = true) && ($event.target.blur())" class="reg_input change_input padding" type="text" placeholder="">
                <input v-model="$store.state.profile.sfere" @focus="(modal2 = true) && ($event.target.blur())" class="reg_input change_input padding" type="text" placeholder="">
                <div class="phorm phorm_textarea">
                <p>О себе</p>
                    <div  class="count">{{length}}/120</div>
                    <textarea v-model="phrase" class="reg_input padding textarea"  maxlength = "120" placeholder="Расскажи немного о себе и своей деятельности (Допускается до 120 символов)">
                </textarea>
            </div>
            </div>
            
            <div class="portfolio padding portfolio_sputnik" style="margin-top: -28px;">
                <p>Портфолио участника</p>
                <div class="lincks">
                    <div @click="modal = true" v-if="portfolio.length < 1" class="linck"><img src="../img/plus.svg" alt=""></div>
                    <div v-if="portfolio.length >= 1" class="port-linck">
                        <img :src="portfolio[0].img" alt="">
                        <img class="btn_dell" @click="portfolio.splice(0, 1)" src="../img/btn_dell.svg" alt="">
                    </div>
                    
                    <div @click="modal = true" v-if="portfolio.length < 2" class="linck">
                        <img src="../img/plus.svg" alt=""></div>
                    <div v-if="portfolio.length >= 2" class="port-linck">
                        <img :src="portfolio[1].img" alt="">
                        <img class="btn_dell" @click="portfolio.splice(1, 1)" src="../img/btn_dell.svg" alt="">
                    </div>
                    
                    <div @click="modal = true" v-if="portfolio.length < 3" class="linck"><img src="../img/plus.svg" alt=""></div>
                    <div v-if="portfolio.length >= 3" class="port-linck">
                        <img :src="portfolio[2].img" alt="">
                        <img class="btn_dell" @click="portfolio.splice(2, 1)" src="../img/btn_dell.svg" alt="">
                    </div>
                    
                    <div @click="modal = true" v-if="portfolio.length < 4" class="linck"><img src="../img/plus.svg" alt=""></div>
                    <div v-if="portfolio.length >= 4" class="port-linck">
                        <img :src="portfolio[3].img" alt="">
                        <img class="btn_dell" @click="portfolio.splice(3, 1)" src="../img/btn_dell.svg" alt="">
                    </div>
                    
                    <div @click="modal = true" v-if="portfolio.length < 5" class="linck"><img src="../img/plus.svg" alt=""></div>
                    <div v-if="portfolio.length >= 5" class="port-linck">
                        <img :src="portfolio[4].img" alt="">
                        <img class="btn_dell" @click="portfolio.splice(4, 1)" src="../img/btn_dell.svg" alt="">
                    </div>
                </div>
            </div>
            <div style="width: calc(100% + 24px);display: flex;
        justify-content: center" v-if="!v$.LastName.$error && !v$.FirstName.$error"><div v-once @click.once="Registration()" class="btn padding"
            onclick="ym(94634027,'reachGoal','myprofile_save'); return true;"
            >Сохранить</div></div>

        </div>
    </div>
    <div class="moda1" :class="{height: modal1 == true}">
        <div class="middle middle_moda1">
            <div class="nav">
                <img @click="modal1 = false" src="../img/arrow.svg" alt="">
                <p class="nav_text">Выбери свою должность</p>
                <div></div>
            </div>
            <div class="phorm"><input v-model="filter" ref='input' class="content_input padding" style="padding-left: 12px; max-width: 100%;" placeholder="Должность" type="text"></div>
            <div class="list"
            @touchmove="$refs.input.blur()"
            v-for="j in filteredListJ()"
            :key="j">
                <p class="list_element" @click="(modal1 = false) || ($store.state.profile.job = j.title) && (jobID = j.id) && (filter = '')" v-html="matchName(j.title)"></p>
            </div>
            <div class="ups" v-if="filteredListJ() == 0">
                <img  src="../img/ups.svg" alt="">
                <p class="ups_text">Упс, должности с таким названием <br>не найдены</p>
            </div>
        </div>
    </div>
    <div class="moda1" :class="{height: modal2 == true}">
        <div class="middle middle_moda1">
            <div class="nav">
                <img @click="modal2 = false" src="../img/arrow.svg" alt="">
                <p class="nav_text">Укажи сферу деятельности</p>
                <div></div>
            </div>
            <div class="phorm"><input v-model="filter" ref='input2' class="content_input padding" style="padding-left: 12px; max-width: 100%;" placeholder="Сфера деятельности" type="text"></div>
            <div class="list"
            @touchmove="$refs.input2.blur()"
            v-for="s in filteredListS()"
            :key="s">
                <p class="list_element" @click="(modal2 = false) || ($store.state.profile.sfere = s.title) && (scopeID = s.id) && (filter = '')" v-html="matchName(s.title)"></p>
            </div>
            <div class="ups" v-if="filteredListS() == 0">
                <img  src="../img/ups.svg" alt="">
            <p class="ups_text">Упс, сферы с таким названием <br>не найдены</p>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, not } from '@vuelidate/validators'
const simbol = (value) => value.includes('`') || 
value.includes('~') || 
value.includes('!') || 
value.includes('@') || 
value.includes('#') ||
value.includes('$') ||
value.includes('%') ||
value.includes('^') ||
value.includes('&') ||
value.includes('*') ||
value.includes('(') ||
value.includes(')') ||
value.includes('_') ||
value.includes('+') ||
value.includes('=') ||
value.includes('[') ||
value.includes(']') ||
value.includes('{') ||
value.includes('}') ||
value.includes('|') ||
value.includes(';') ||
value.includes('’') ||
value.includes(':') ||
value.includes('”') ||
value.includes(',') ||
value.includes('.') ||
value.includes('/') ||
value.includes('<') ||
value.includes('>') ||
value.includes('?') ||
value.includes('£') ||
value.includes('0')
const number = (value) => value.includes('1') || 
value.includes('2') || 
value.includes('3') || 
value.includes('4') || 
value.includes('5') ||
value.includes('6') ||
value.includes('7') ||
value.includes('8') ||
value.includes('9') ||
value.includes('0')
const toUpperCase = (value) => value.includes(value[0].toUpperCase())
export default {
    setup () {
        return { v$: useVuelidate() } 
    },
    data(){
        return {
            NOiconOk: 'circle.svg',
            user: [],
            userJob: [],
            phrase: '',
            filter: '',
            jobID: '',
            scopeID: '',
            modal: false,
            modal1: false,
            modal2: false,
            portfolio: this.$store.state.UserLinks,
            error: false,
            links: [],
            link: '',
            FirstName: '',
            LastName: '',
            icon1: false,
            icon2: false,
            clicked: false,
            border: false,
            isRequestSent: false,
            loader: false
        }
    },
    computed: {
        length: function () {
        return this.phrase.length;
        },
    },
    validations () {
        return {
        FirstName: {
            required,
            maxLengthValue: maxLength( 20 ),
            number: not(number),
            space: not(simbol),
            toUpperCase: toUpperCase
        },
        LastName: {
            required,
            maxLengthValue: maxLength( 20 ),
            space: not(simbol),
            number: not(number),
            toUpperCase: toUpperCase
        }, 
        }
    },
    methods:  {
        Console(){
            console.log(this.FirstName)
        },
    Border() {
        if (this.link == '') {
         this.border = true   
        } else {
           this.link = '',
           this.border = false 
        }
    },
    rout(r) {
        this.$router.push(r);
    },
    backRout() {
        this.$router.go(-1)
    },
    filteredListJ() {
    return this.$store.state.jobs.filter(jobs => jobs.title.toLowerCase().includes(this.filter.toLowerCase()))
    },
    filteredListS() {
        return this.$store.state.sfere.filter(sfere => sfere.title.toLowerCase().includes(this.filter.toLowerCase()))
    },
    matchName(current) {
        const reggie = new RegExp(this.filter , "ig");
        const found = current.search(reggie) !== -1; 
        return !found ? current : current.replace(reggie, '<span class="text_gradient">' + "$&" + '</span>');
    },
    filteredList() {
        let filteredSocialNetworks = [];
        for (let i = 0; i < this.$store.state.Network.length; i++) {
            const size = Object.keys(this.$store.state.Network[i].url_patterns);
            
            // let filterLink = this.link.split('https://' || 'www.')[1]
                    if (this.link.toLowerCase().includes('https://')) {
                        if (this.link.toLowerCase().includes('www.')) {
                        this.linkFilter = this.link.split('www.')[1]
                        } else {
                        this.linkFilter = this.link.split('https://')[1] 
                        }
                        } else if (this.link.toLowerCase().includes('www.')){
                         this.linkFilter = this.link.split('www.')[1]
                            } else {
                        this.linkFilter = this.link
                        }


            for (let j = 0; j < size.length; j++) {
                if (this.link.toLowerCase().includes('.com')) {
                 if (this.$store.state.Network[i].url_patterns[size[j]].toLowerCase().includes(this.linkFilter.toLowerCase().split('.com')[0])) {
                    filteredSocialNetworks.push(this.$store.state.Network[i]);
                    break;
                    }
                }
                if (this.link.toLowerCase().includes('.ru')) {
                 if (this.$store.state.Network[i].url_patterns[size[j]].toLowerCase().includes(this.linkFilter.toLowerCase().split('.ru')[0])) {
                    filteredSocialNetworks.push(this.$store.state.Network[i]);
                    break;
                }
            }
                if (this.link.toLowerCase().includes('.org')) {
                 if (this.$store.state.Network[i].url_patterns[size[j]].toLowerCase().includes(this.linkFilter.toLowerCase().split('.org')[0])) {
                    filteredSocialNetworks.push(this.$store.state.Network[i]);
                    break;
                }
            }
                if (this.link.toLowerCase().includes('.be')) {
                 if (this.$store.state.Network[i].url_patterns[size[j]].toLowerCase().includes(this.linkFilter.toLowerCase().split('.be')[0])) {
                    filteredSocialNetworks.push(this.$store.state.Network[i]);
                    break;
                }
            }
            }
        }
        return filteredSocialNetworks;
        },
    addLink() {
        if(this.clicked == false) {
        this.clicked = true
        if (this.link == '') {
          this.error = false  
        }
        else if( this.filteredList().length == 1) {
            if (this.portfolio.length == 0) {
            const newRequest = {
                link: this.link,
                network: this.filteredList()[0].id,
                img: this.filteredList()[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true
            this.error = !true
            } else if ((this.portfolio.length == 1) && (this.portfolio[0].img != this.filteredList()[0].file_ref)) {
            const newRequest = {
                link: this.link,
                network: this.filteredList()[0].id,
                img: this.filteredList()[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true
            this.error = !true
            } else if ((this.portfolio.length == 2) && 
            (this.portfolio[0].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[1].img != this.filteredList()[0].file_ref)) {
            const newRequest = {
                link: this.link,
                network: this.filteredList()[0].id,
                img: this.filteredList()[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true
            this.error = !true
            } else if ((this.portfolio.length == 3) && 
            (this.portfolio[0].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[1].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[2].img != this.filteredList()[0].file_ref)) {
            const newRequest = {
                link: this.link,
                network: this.filteredList()[0].id,
                img: this.filteredList()[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true
            this.error = !true
            } else if ((this.portfolio.length == 4) && 
            (this.portfolio[0].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[1].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[2].img != this.filteredList()[0].file_ref)  && 
            (this.portfolio[3].img != this.filteredList()[0].file_ref)) {
            const newRequest = {
                link: this.link,
                network: this.filteredList()[0].id,
                img: this.filteredList()[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true
            this.error = !true
            } else if ((this.portfolio.length == 5) && 
            (this.portfolio[0].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[1].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[2].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[3].img != this.filteredList()[0].file_ref) && 
            (this.portfolio[4].img != this.filteredList()[0].file_ref)) {
            const newRequest = {
                link: this.link,
                network: this.filteredList()[0].id,
                img: this.filteredList()[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true
            this.error = !true
            }
             else {
              this.error = true   
            }
        }
         else {
            if (this.portfolio.length == 0) {
            const newRequest = {
                link: this.link,
                network: this.$store.state.Network[0].id,
                img: this.$store.state.Network[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true;
            this.error = false
            } else  if ((this.portfolio.length == 1) && (this.portfolio[0].img != this.$store.state.Network[0].file_ref)){
            const newRequest = {
                link: this.link,
                network: this.$store.state.Network[0].id,
                img: this.$store.state.Network[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true;
            this.error = false
            }  else  if ((this.portfolio.length == 2) &&
             (this.portfolio[0].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[1].img != this.$store.state.Network[0].file_ref)){
            const newRequest = {
                link: this.link,
                network: this.$store.state.Network[0].id,
                img: this.$store.state.Network[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true;
            this.error = false
            }  else  if ((this.portfolio.length == 3) &&
             (this.portfolio[0].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[1].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[2].img != this.$store.state.Network[0].file_ref)){
            const newRequest = {
                link: this.link,
                network: this.$store.state.Network[0].id,
                img: this.$store.state.Network[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true;
            this.error = false
            }  else  if ((this.portfolio.length == 4) &&
             (this.portfolio[0].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[1].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[2].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[3].img != this.$store.state.Network[0].file_ref)){
            const newRequest = {
                link: this.link,
                network: this.$store.state.Network[0].id,
                img: this.$store.state.Network[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true;
            this.error = false
            }   else  if ((this.portfolio.length == 5) &&
             (this.portfolio[0].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[1].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[2].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[3].img != this.$store.state.Network[0].file_ref) &&
             (this.portfolio[4].img != this.$store.state.Network[0].file_ref)){
            const newRequest = {
                link: this.link,
                network: this.$store.state.Network[0].id,
                img: this.$store.state.Network[0].file_ref
                };
            this.portfolio.push(newRequest);
            this.modal = !true;
            this.error = false
            }
             else {
              this.error = true   
            }
        }
        this.clicked = false
    }
    },
    Registration() {
      this.$store.state.loader = false
    for (let i = 0; i < this.portfolio.length; i++) {
      const put = {
        network: this.portfolio[i].network,
        link: this.portfolio[i].link,
    };
    this.links.push(put);
    }
        fetch(this.$store.state.ApiLink +'/api/users/'+ this.$store.state.UserId + 
        '?user_sign=' + this.$store.state.token + 
        '&first_name_app=' + this.FirstName + 
        '&last_name_app=' + this.LastName + 
        '&role=' + this.jobID + 
        '&specialization=' + this.scopeID +
        '&about_info=' + this.phrase, {
        method: 'PUT' ,
        body: JSON.stringify(this.links)
    })
    this.loader = true
    setTimeout(async() =>{
    this.$router.push('/Profile' + '?sign=' + this.$store.state.token)
    }, 1500)  
   },
    },
    async mounted() {
        this.$store.state.loader = true
        const users = await fetch(this.$store.state.ApiLink + '/api/users/' + this.$store.state.UserId + '?user_sign=' + this.$store.state.token)
        const dataUsers = await users.json();
        this.user = dataUsers
        this.phrase = dataUsers.about_info
        this.jobID = dataUsers.role_id
        this.FirstName = dataUsers.first_name_app
        this.LastName = dataUsers.last_name_app;
        this.scopeID = dataUsers.specialization_id
        if(this.$store.state.profile == '') {
            this.$router.push('/Profile' + '?sign=' + this.$store.state.token)
        }
        this.$store.state.loader = false
    },
    beforeMount(){
        this.$store.state.token = window.location.hash.split('?sign=')[1];
    }
}
</script>
<style>
    .check_icon {
        position: absolute;
    }
    .icon_img {
        min-height: 20px;
        /* margin-top: 3px */
    }
    .invalid {
        border: 1px solid #EC6C6C;
    }
    .middle_moda1 {
        gap: 20px;
    }
    .list_element:active {
        background: #CCE0FF;
    }
    .check_icon {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 0;
        top: 9px;
        gap: 32px;
    }
    .icon_img{
        min-height: 21px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .invalid {
        border: 1px solid #EC6C6C;
    }
    .ups {
        text-align: center;
    }
.input_modal_block {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
}
.input_modal_block .linck {
    min-width: 43px;
    height: 43px;
}
.input_modal_block .linck img{
    width: 20px;
    height: 20px;
}
.port-linck {
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 4.5px;
}
.btn_dell {
    position: absolute;
    right: -10px;
    top: -10px;
}
.errorBorder {
    border: 1px solid red;
}
.back_arrow {
    position: absolute;
    left: -8px;
    top: -36px;
}
.change {
    margin-top: 35px;
}
</style>