import { createStore } from 'vuex'
let tg = window.Telegram.WebApp
const store = createStore({
    state() {
        return {
            jobs: [],
            sfere: [],
            Network: [],

            FirstName: [],
            LastName: [],
            job: [],
            scope: [],
            modal: true,

            loader: true,
            ApiLink: process.env.VUE_APP_API_ROOT,

            // UserId: '1227049925',
            // token: 'DfMQXRJLRo09W8GiCyau',

            token: window.location.hash.split('=')[1],
            UserId: tg.initDataUnsafe.user.id,
            profile: []
        }
    },
    mutations: {

    },
    actions: {

    }
})

export default store