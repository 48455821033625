<template>
  <div class="main">
    <!-- <button style="width: 70px;" class="btn" @click="rout('/')">reg</button> -->
    <div class="loader">
      <div v-if="this.$store.state.loader == true" class="loader-38"></div>
    </div>
   <router-view/>
  </div>
</template>

<script>
let tg = window.Telegram.WebApp
export default {
  data() {
    return {
      jobs: [],
      sfere: [],
    }
  },
  created () {
    document.addEventListener('touchstart', this.swipe1 , false);
    document.addEventListener('touchmove', this.swipe2 , false);
  },
  methods:  {
    rout(r) {
        this.$router.push(r);
    },
  swipe1(event) {
    const FistTouch = event.touches[0];
    this.x1 = FistTouch.clientX
    this.y1 = FistTouch.clientY
  },
  swipe2(event) {
    if (!this.x1 || !this.y1) {
      return false
    }
    let x2 = event.touches[0].clientX;
    let y2 = event.touches[0].clientY;
    let xDiff = x2 - this.x1;
    let yDiff = y2 - this.y1;
    // console.log(xDiff, yDiff)
    if ((Math.abs(xDiff) > Math.abs(yDiff)) && (window.location.hash.split('?')[0] != '#/MyRegistrationFinal')) {
      if (xDiff > 50) {
        this.$router.go(-1)
      }
    }
    else {
      return false
    }
  },
  },
  async mounted() {
    this.$store.state.loader = true
    const roles = await fetch(this.$store.state.ApiLink +'/api/roles')
    const dataroles = await roles.json();
    const specializations = await fetch(this.$store.state.ApiLink +'/api/specializations')
    const dataspecializations = await specializations.json();
    const Network = await fetch(this.$store.state.ApiLink + '/api/networks');
    const NetworkData = await Network.json();
    for (let i = 0; i < dataroles.length; i++) {
      const Data = {
          id: dataroles[i].id,
          title: dataroles[i].title,
        };
        this.jobs.push(Data);
        this.$store.state.jobs = this.jobs
    }
      for (let i = 0; i < dataspecializations.length; i++) {
      const Data = {
          id: dataspecializations[i].id,
          title: dataspecializations[i].title,
        };
        this.sfere.push(Data);
        this.$store.state.sfere = this.sfere
    }
    this.$store.state.Network = NetworkData
    this.$store.state.loader = false
  },
beforeMount(){
    tg.expand();
    tg.enableClosingConfirmation()
    // this.$store.state.token = window.location.hash.split('=')[1]
}
}
</script>
<style src="./main.css">
</style>
