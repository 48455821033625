import { createRouter, createWebHashHistory } from 'vue-router'
import SputnikiProfile from '../views/SputnikiProfile.vue'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/SputnikiProfile',
      name: 'home',
      component: SputnikiProfile,
      props: true
    },
    {
      path: '/Profile',
      name: 'Profile',
      component: () => import('../views/NewProfile.vue'),
      props: true
    },
    {
      path: '/MySputniks',
      name: 'MySputniks',
      component: () => import('../views/MySputniks.vue'),
      props: true
    },
    {
      path: '/MySputnik/:id',
      name: 'MySputnik',
      component: () => import('../views/MySputnik.vue'),
      props: true
    },
    {
      path: '/MyChats',
      name: 'MyChats',
      component: () => import('../views/MyChats.vue'),
    },
    {
      path: '/ChatProfile/:id',
      name: 'ChatProfile',
      component: () => import('../views/ChatProfile.vue'),
      props: true
    },
    {
      path: '/',
      name: 'MyRegistration',
      component: () => import('../views/MyRegistration.vue'),
      props: true
    },
    {
      path: '/MyRegistrationExperience',
      name: 'MyRegistrationExperience',
      component: () => import('../views/MyRegistrationExperience.vue'),
      props: true
    },
    {
      path: '/MyRegistrationFinal',
      name: 'MyRegistrationFinal',
      component: () => import('../views/MyRegistrationFinal.vue'),
    },
  ]
})

export default router
